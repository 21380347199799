/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
var FormControls = function () {
    //== Private functions

    var senForm = function () {
        $("#form_login_user").validate({
            // define validation rules
            rules: {
                '_username': {
                    required: true,
                },
                '_password': {
                    required: true
                }
            },

            messages: {
                '_username': {
                    required: "Indica tu usuario",
                },
                '_password': {
                    required: "Indica tu contraseña",
                }
            },

            //display error alert on form submit  
            invalidHandler: function (event, validator) {
                var alert = $('#m_form_1_msg');
                alert.removeClass('m--hide').show();
                mApp.scrollTo(alert, -200);
            },

            submitHandler: function (form) {
                form[0].submit(); // submit the form
            }
        });
    }
    return {
        // public functions
        init: function () {
            senForm();
        }
    };
}();

jQuery(document).ready(function () {
    FormControls.init();
});


